import {
  IonContent,
  IonItem,
  IonLabel, IonList, IonPage,
  IonFooter, IonRouterLink, IonChip, IonIcon, IonItemDivider, IonCard, IonCardHeader, IonCardTitle, IonCardContent, useIonAlert, IonButton, IonBadge, IonText
} from '@ionic/react';

import React, { useEffect } from 'react';

import { Api } from '../data/api'
import { Job, Roster, Shift } from '../model/User';
import FooterImage from '../components/FooterImage';
import Header from '../components/Header';
import Moment from 'react-moment';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';

const ShiftListItem: React.FC<{ api: Api; shift: Shift; job: Job }> = ({ api, shift, job }) => {
  const [presentAlert] = useIonAlert();

  const start = new Date(shift.start_time);
  const end = new Date(shift.end_time);
  const today = new Date();
  const inPast = end < today;

  const sameday = start.toDateString() === end.toDateString();

  const hours = Math.abs(end.getTime() - start.getTime()) / (1000 * 60 * 60);

  const volsGot = api.jobshiftvols?.reduce((accumulator, currentValue) => {
    if (currentValue.job_id === job.id && currentValue.shift_id === shift.id) return accumulator + currentValue.count;

    return accumulator;
  }, 0);

  const [volunteersCount, setVolunteersCount] = React.useState(volsGot || 0);

  const calculateProgressBarValues = () => {
    const successPercent = (volunteersCount && job.volunteers_max > 0) ? Math.round((volunteersCount / job.volunteers_max) * 100) : 0;
    let minVolsNeededPercent = (job.volunteers_needed && job.volunteers_max > 0) ? Math.round((job.volunteers_needed / job.volunteers_max) * 100) : 0;
  
    if (successPercent >= minVolsNeededPercent) {
      minVolsNeededPercent = 0;
    }
    const maxPercentage = 100 - successPercent - minVolsNeededPercent;
    const isFull = volunteersCount ? volunteersCount >= job.volunteers_max : false; 
    return { successPercent, minVolsNeededPercent, maxPercentage, isFull };
  };
  
  const { successPercent, minVolsNeededPercent, maxPercentage, isFull } = calculateProgressBarValues();


  const onRosterOn = async () => {

    const response = await api.rosterOn(shift, job.area, job);
    if (response?.status !== 200) {
      const $message = response.message ? response.message : 'Error saving, please check inputs and try again';
      presentAlert($message, [{ text: 'Ok' }]);
    }else {
      setVolunteersCount(volunteersCount + 1);
      calculateProgressBarValues();
    }
  };

  const onRosterOff = (roster: Roster) => {
    presentAlert({
      header: 'Are you sure you would like to remove this roster?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: async () => {
            const response = await api.rosterOff(roster);
            if (response?.status !== 200) {
              const $message = response.message ? response.message : 'Error saving, please check inputs and try again';
              presentAlert($message, [{ text: 'Ok' }]);
            }else {
              setVolunteersCount(volunteersCount - 1);
              calculateProgressBarValues();
            }
          },
        },
      ]
    })
  };

  //get the current roster if rostered on
  const isRosteredOn = api.volunteer?.rosters?.find(roster => roster.job_id === job.id && roster.shift_id === shift.id);

  const isBusy = api.volunteer?.rosters?.some(roster => {
    if (roster.shift_id === shift.id) {
      return true;
    }

    return false;
  });

  const canRoster = job.type === 'self-rostering';

  return inPast ? <></> : <IonCard>
    <IonCardContent>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex flex-column'>
          <IonText className='fs-6 fw-bolder'>{shift.title}<IonBadge color="success" slot="end" >{hours} Hrs</IonBadge></IonText>
          <div className='' style={{fontSize: 14}}><Moment format="ddd Do MMM h:mm a" >{shift.start_time}</Moment>&nbsp;-&nbsp;
          {sameday ? <Moment format="h:mm a" >{shift.end_time}</Moment> : <Moment format="ddd Do MMM h:mm a" >{shift.end_time}</Moment>}</div>
        </div>
        <div>
          {isRosteredOn && <IonButton color='danger'  size="small"
            onClick={() => onRosterOff(isRosteredOn)}>Cancel
          </IonButton>}

          {!isRosteredOn && canRoster && !isBusy && !isFull && <IonButton onClick={onRosterOn} size="small" >Roster On</IonButton>}

          {!isRosteredOn && canRoster && !isBusy && isFull && <IonButton color='secondary' disabled size="small" >Shift Full</IonButton>}

          {!isRosteredOn && isBusy && <IonButton color='success' disabled size="small">Already rostered</IonButton>}

          {!canRoster && <IonButton color='warning' disabled size="small" >Recruit Only</IonButton>}
        </div>
      </div>
      <ProgressBar>
          <ProgressBar variant="success" now={successPercent} />
          <ProgressBar variant="danger" now={minVolsNeededPercent} label="min" />
          <ProgressBar now={maxPercentage} label="max" />;
      </ProgressBar>

      
    </IonCardContent>
  </IonCard>
}

const JobPage: React.FC<{ api: Api, job: Job, handleDismiss: () => void }> = ({ api, job, handleDismiss }) => {

  useEffect(() => {
    console.log('job page load');

  }, []);

  return (
    <IonPage>
      <Header title={job.title} api={api} backCallback={handleDismiss} />
      <IonContent fullscreen>
        <IonContent>
          {job.type !== 'self-rostering' &&
            <IonCard color='warning'>
              <IonCardHeader>
                <IonCardTitle>This is a recruited position</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                This job requites special skills or certifications. If you would like to volunteer for this role please contact the area leader.
              </IonCardContent>
            </IonCard>
          }
          <IonCard>
            <IonCardHeader>
              <IonCardTitle><FontAwesomeIcon icon={faLayerGroup} /> {job.area.title}</IonCardTitle>

            </IonCardHeader>
            <IonCardContent>
              <div dangerouslySetInnerHTML={{ __html: job.description }} />
            </IonCardContent>
          </IonCard>

          {job.shifts.map((shift, index) => <ShiftListItem key={index} shift={shift} api={api} job={job} />)}
        </IonContent>
      </IonContent>
      <IonFooter>
        <FooterImage />
      </IonFooter>
    </IonPage>
  );
};

export default JobPage;
